import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FacebookLoginClient} from '@greatsumini/react-facebook-login';
import debug from '../../../../../../../../../shared/lib/debug';
import AdministrationBusinessPageChannelsConnect from '../administration-business-page-channels-connect-component';
import {davysGrey, lightYellow, mediumGreen} from '../../../../../../../../../shared/style/colors';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';
import {FACEBOOK_WHATSAPPP_SENDER_CONFIGURATION} from '../../../../lib/channels-helper';
import ErrorMessageAdvanced from '../../../../../../common/error-message-advanced-component';

const AdministrationBusinessPageChannelsWhatsAppComponent = ({
  i18n,
  onAdministrationBusinessPageChannelsWhatsAppMounted,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrievalError,
  businessWhatsappState
}) => {
  useEffect(() => {
    onAdministrationBusinessPageChannelsWhatsAppMounted();

    FacebookLoginClient.loadSdk();

    const embeddedSignupInfoListener = (event) => {
      if (!event.origin.endsWith('facebook.com')) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH' || data.event === 'FINISH_ONLY_WABA') {
            const {waba_id: wabaId} = data.data;
            onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved(wabaId);
          } else if (data.event === 'ERROR') {
            const {error_message: errorMessage} = data.data;
            debug(`WhatsApp sender flow failed:${errorMessage}`);
            onAdministrationBusinessPageChannelsWhatsAppWabaRetrievalError(errorMessage);
          }
        }
      } catch {
        debug(`WhatsApp sender flow - non JSON Response :${event.data}`);
      }
    };

    window.addEventListener('message', embeddedSignupInfoListener);

    return () => {
      window.removeEventListener('message', embeddedSignupInfoListener);
    };
  }, []);

  if (businessWhatsappState.loading) {
    return <LoadingIndicator />;
  }

  if (businessWhatsappState.error) {
    debug(
      `Error loading WhatsApp sender connected state. ${JSON.stringify(
        businessWhatsappState.error
      )}`
    );

    return (
      <ErrorMessageAdvanced
        title={i18n.t('administration.business.pages.channels.whatsapp.errorDefaultMessage')}
      />
    );
  }

  const {appId, version, configId, solutionId} = FACEBOOK_WHATSAPPP_SENDER_CONFIGURATION;
  const whatsAppIcon = `${ASSETS_PATH}/img/icon/channel/WHATSAPP.svg`;
  const senderStatus = businessWhatsappState.sender.status;

  const renderChannelDisconnectButton = () => {
    return (
      <AdministrationBusinessPageChannelsConnect
        icon={whatsAppIcon}
        title={i18n.t('administration.business.pages.channels.whatsapp.itemTitle')}
        channelConnected
        switchButtonColor={senderStatus === 'ONLINE' ? mediumGreen : lightYellow}
        switchButtonDataTestId="todo"
        labelButton=""
        isSwitchButtonChecked
        onHandleChange={() => {}}
      />
    );
  };

  const doFacebookLogin = (event) => {
    if (event.target.checked) {
      // Initialize Facebook SDK with app ID to support multiple Facebook apps in the same page
      FacebookLoginClient.init({appId, version});

      // We have to do the login manually instead of using the component
      // because we need to set the config_id and solution_id
      window.FB.login(() => {}, {
        auth_type: 'rerequest', // Avoids 'user is already logged' in errors if users click the button again before refreshing the page
        response_type: 'code',
        config_id: configId,
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 3, // Required to get WABA ID
          featureType: 'only_waba_sharing',
          setup: {
            solutionID: solutionId
          }
        }
      });
    }
  };

  return (
    <div>
      <div>
        {senderStatus === 'NONE' ? (
          <AdministrationBusinessPageChannelsConnect
            icon={whatsAppIcon}
            title={i18n.t('administration.business.pages.channels.whatsapp.itemTitle')}
            channelConnected={businessWhatsappState.whatsAppSenderConnected}
            switchButtonColor={davysGrey}
            labelButton={i18n.t(
              'administration.business.pages.channels.whatsapp.itemLabelButtonConnect'
            )}
            switchButtonDataTestId="todo"
            isSwitchButtonChecked={businessWhatsappState.whatsAppSenderConnected === true}
            onHandleChange={(event) => doFacebookLogin(event)}
          />
        ) : (
          renderChannelDisconnectButton()
        )}
      </div>
    </div>
  );
};

AdministrationBusinessPageChannelsWhatsAppComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onAdministrationBusinessPageChannelsWhatsAppMounted: PropTypes.func.isRequired,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved: PropTypes.func.isRequired,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrievalError: PropTypes.func.isRequired,
  businessWhatsappState: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationBusinessPageChannelsWhatsAppComponent;
