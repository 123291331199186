export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_LAYOUT_MOUNTED =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_LAYOUT_MOUNTED';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVED =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVED';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVAL_ERROR =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVAL_ERROR';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE';
